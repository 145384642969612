import { defineStore } from 'pinia';

interface Point {
  lat: number;
  lng: number;
}

type View = 'default' | 'map';

interface SearchItem {
  text: string;
  value: string;
  type: string;
}

interface Camera {
  longitude: number;
  latitude: number;
  height: number;
  heading: number;
  pitch: number;
  roll: number;
}

export const useSearchStore = defineStore('search', {
  state: () => {
    return {
      propertiesSearchParams: [] as string[],
      projectSearchParams: [] as string[],
      buildingFilters: {} as Record<string, unknown>,
      projectLink: '',
      customPolygon: null as Point[][][] | null,
      view: 'default' as View,
      query: { text: '', value: '', type: 'void' } as SearchItem,
      savedQueries: [] as SearchItem[],
      bounds: null as {
        left: number | null;
        top: number | null;
        right: number | null;
        bottom: number | null;
        county?: string;
        city?: string;
        zipcode?: string;
      } | null,
      zoom: null,
      center: { lat: 25.761681, lng: -80.191788 },
      camera: {
        longitude: 0,
        latitude: 0,
        height: 0,
        heading: 0,
        pitch: 0,
        roll: 0
      } as Camera
    };
  },
  persist: {
    storage: persistedState.sessionStorage
  },
  actions: {
    setPropertiesSearchParams(params: string[]) {
      this.propertiesSearchParams = params;
      this.projectLink = '';
    },
    setProjectSearchParams(params: string[]) {
      this.projectSearchParams = params;
    },
    setBuildingFilters(params: Record<string, unknown>) {
      this.buildingFilters = params;
    },
    setProjectLink(link: string) {
      this.projectLink = link;
    },
    setCustomPolygon(polygon: Point[][][] | null) {
      this.customPolygon = polygon;
    },
    setView(view: View = 'default') {
      this.view = view;
    },
    setQuery(query: SearchItem | string) {
      console.log('setQuery', query);
      if (typeof query === 'string') {
        this.query = { text: query, value: query, type: 'void' };
      } else {
        this.query = query;
      }
    },
    setBounds(
      bounds: {
        left: number | null;
        top: number | null;
        right: number | null;
        bottom: number | null;
        county?: string;
        city?: string;
        zipcode?: string;
      } | null
    ) {
      this.bounds = bounds;
    },
    setZoom(zoom: number) {
      this.zoom = zoom;
    },
    setCenter(center: Point) {
      this.center = center;
    },
    setCamera(camera: Camera) {
      this.camera = camera;
    },
    saveQueryToStorage() {
      const query = this.query;
      if (!query?.type || query.type === 'void') return;
      const newSavedQueries = [query, ...this.savedQueries];
      // remove duplicates in reverse
      for (let i = newSavedQueries.length - 1; i >= 0; i--) {
        for (let j = i - 1; j >= 0; j--) {
          if (newSavedQueries[i].id === newSavedQueries[j].id) {
            newSavedQueries.splice(i, 1);
            break;
          }
        }
      }
      this.savedQueries = newSavedQueries.slice(0, 3);
    }
  }
});
